
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State } from "vuex-class";
import EmailConfiguration from "@/components/Settings/Sections/CommunicationChannels/EmailConfiguration/Components/EmailConfiguration.vue";
import DefaultColumns from "@/components/Settings/Sections/BaseSettings/DefaultColumns/Components/DefaultColumns.vue";
import DefaultPlanboardTaskSettings from "@/components/Settings/Sections/BaseSettings/DefaultPlanboardSettings/Components/DefaultPlanboardTaskSettings.vue";
import TemplateConstructor from "@/components/Settings/Sections/Templates/EmailTemplates/Components/TemplateConstructor.vue";
import PdfTemplates from "@/components/Settings/Sections/Templates/PDFgenerator/Components/PdfTemplates.vue";
import DocGenerator from "@/components/Settings/Sections/Templates/DocGenerator/Components/DocGenerator.vue";
import ReportsTemplates from "@/components/Settings/Sections/Templates/ReportsTemplates/Components/ReportsTemplates.vue";
import EmailTemplates from "@/components/Settings/Sections/Templates/EmailTemplates/Components/EmailTemplates.vue";
import FormBuilderView from "@/components/Builder/FormBuilderView.vue";
import ImportData from "@/components/Settings/Sections/DataManagement/ImportData/Components/ImportData.vue";
import SyncElasticSearch from "@/components/Settings/Sections/DataManagement/SyncElasticSearch/Components/SyncElasticSearch.vue";
import DeletedRecords from "@/components/Settings/Sections/DataManagement/DeletedRecords/Components/DeletedRecords.vue";
import AuditLogsExport from "@/components/Settings/Sections/DataManagement/AuditLogsExport/Components/AuditLogsExport.vue";
import PortalSettings from "@/components/Settings/Sections/General/PortalSettings/Components/PortalSettings.vue";
import OrganisationSettings from "@/components/Settings/Sections/General/OrganisationSettings/Components/OrganisationSettings.vue";
import RoleManagement from "@/components/Settings/Sections/UserManagement/RoleManagement/Components/RoleManagement.vue";
import UserConfiguration from "@/components/Settings/Sections/UserManagement/UserConfiguration/Components/UserConfiguration.vue";
import DefaultOrderInfo from "@/components/Settings/Sections/BaseSettings/DefaultOrderInfo/Components/DefaultOrderInfo.vue";
import RoboticProcessBuilder from "@/components/Settings/Sections/ProcessOptimization/RoboticProcessBuilder/Components/RoProBuilder.vue";
import StatusConfiguration from "@/components/Settings/Sections/ProcessOptimization/StatusConfiguration/Components/StatusConfiguration.vue";
import ReleaseNotes from "@/components/Settings/Sections/General/ReleaseNotes/Components/ReleaseNotes.vue";
import store from "@/store";
import SettingsList from "./Models/Settings.json";
import Settings from "./Models/Settings";
import Setting from "./Models/Setting";

@Component({
  components: {
    DefaultColumns,
    DefaultPlanboardTaskSettings,
    EmailConfiguration,
    TemplateConstructor,
    PdfTemplates,
    DocGenerator,
    EmailTemplates,
    FormBuilderView,
    SyncElasticSearch,
    ReportsTemplates,
    RoboticProcessBuilder,
    DeletedRecords,
    ImportData,
    PortalSettings,
    AuditLogsExport,
    RoleManagement,
    UserConfiguration,
    DefaultOrderInfo,
    StatusConfiguration,
    ReleaseNotes,
    OrganisationSettings,
  },
})
export default class SettingsPage extends Vue {
  @Prop(String) userType;
  @Prop(String) action;

  @State("ACL") ACL;
  @State("dynamicModules") dynamicModules;

  panel = "admin";
  userTab = "emailTemplates";
  filterValue: any = "";
  templateSettingsTab = "settings";
  adminSplitterModel = 270;
  userSplitterModel = 270;
  guard: any = null;
  validRoProBuilderLicense = false;
  adminModules = {};
  settings = new Settings();
  allSettings: Array<Setting> = [];
  allSections: Array<string> = [];
  filteredStatuses = [];

  async mounted() {
    if (
      store.state &&
      store.state.licenses &&
      store.state.licenses.RoProBuilder &&
      store.state.licenses.RoProBuilder.active
    ) {
      this.validRoProBuilderLicense = true;
    }

    const modules = [];
    for (const m in this.dynamicModules) {
      const module = this.dynamicModules[m];
      if (module.id.includes("admin") && !module.hidden) {
        module["label"] = module.pluralName;
        modules.push(module);
      }
    }
    let index = 0
    this.adminModules["1"] = []
    for(const module of modules) {
      if(index < 5) {
        this.adminModules["1"].push(module)
      } else if (index > 4 && index < 10) {
        if(!this.adminModules["2"]) this.adminModules["2"] = []
        this.adminModules["2"].push(module)
      } else {
        if(!this.adminModules["3"]) this.adminModules["3"] = []
        this.adminModules["3"].push(module)
      }
      index += 1
    }

    // this.adminModules = modules;
    for (const setting of SettingsList) {
      this.settings.addSetting(setting);
    }

    if (this.ACL.includes("admin")) {
      this.allSettings = this.settings.getAllSettings();
      this.allSections = this.settings.getSections();
    } else {
      this.allSettings = this.settings.getVisibleSettings();
      this.allSections = this.settings.getVisibleSections();
    }
  }

  getAdminModuleLabel(module) {
    const translationKey = `modules.${module.id}.pluralName`
    if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
      return store.state.fieldTranslations[translationKey]
    }
    return module.pluralName
  }

  filterFn(val, update) {
    if (val === "") {
      this.filterValue = "";
    }
    if (val.length < 3) {
      return;
    }
    this.filteredStatuses = [];
    this.filterValue = val;
    const adminModules = []

    for(const page in this.adminModules) {
      for(const adminModule of this.adminModules[page]) {
        adminModules.push(adminModule)

      }
    }
    const list = adminModules.concat(this.allSettings as any);
    update(() => {
      const needle = val.toLowerCase(); 
      this.filteredStatuses = list.filter((v) => {
        let label: any = v.label;
        if (v.labelTranslationKey) {
          label = this.$t(v.labelTranslationKey);
          v.label = this.$t(v.labelTranslationKey);
        }
        return label.toLowerCase().includes(needle) == true;
      });
    });
  }

  goToSetting(value) {
    const settingLabels = [];
    const adminModules = []
    for(const page in this.adminModules) {
      adminModules.concat(this.adminModules[page])
    }
    const list = adminModules.concat(this.allSettings as any);
    for (const setting of list) {
      settingLabels.push(setting.label.toLowerCase());
    }
    if (settingLabels.includes(value.toLowerCase())) {
      for (const setting of list) {
        if (setting.label.toLowerCase() == value.toLowerCase()) {
          if (setting.id && setting.id.toLowerCase().includes("admin")) {
            this.$router.push(`/modules/${module.id}`);
          } else {
            this.$router.push({ name: setting["name"] });
          }
        }
      }
    }
    if (!settingLabels.includes(value.toLowerCase())) {
      if (this.filteredStatuses.length) {
        if (
          this.filteredStatuses[0].id &&
          this.filteredStatuses[0].id.toLowerCase().includes("admin")
        ) {
          this.$router.push(`/modules/${this.filteredStatuses[0].id}`);
        } else {
          this.$router.push({ name: this.filteredStatuses[0]["name"] });
        }
      }
    }
  }

  checkIfNew(dateAdded) {
    const date = new Date().toLocaleDateString("en-US", {
      month: "2-digit",
      year: "numeric",
    });
    if (dateAdded.split("-")[2] === date.split("/")[1]) {
      const month: any = date.split("/")[0];
      if (dateAdded.split("-")[1] > month - 3) {
        return true;
      }
    }
    return false;
  }

  getModuleTitleLabel(index: number) {
    let label = ""
    const pages = Object.keys(this.adminModules)
    if(pages.length == 1) label = ""
    else if(pages.length > 1) label = `${index}/${pages.length}`
    return `${this.$t("settings.headers.environmentSettings")} ${label}`
  }

  @Watch("filterValue")
  filterSettings(setting, type) {
    if (typeof this.filterValue === "object") {
      this.filterValue = this.filterValue.label
      this.goToSetting(this.filterValue);
    }
    let label: any = "";
    if (type == "module") {
      label = setting.pluralName;
    }
    if (type == "setting") {
      label = setting.label;
    }
    if (setting.labelTranslationKey) {
      label = this.$t(setting.labelTranslationKey);
    }
    if (
      this.filterValue !== "" &&
      label.toLowerCase().includes(this.filterValue.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  }
}
