
import {mapActions, mapState} from "vuex";

export default {
  data: function () {
    return {
      showCreateDialog: false,
      showDeleteDialog: false,
      showCopyDialog: false,
      newNameValid: false,
      currentModule: {},
      newModuleName: '',
      columns: [
        {
          name: 'module',
          label: 'Module',
          field: 'module',
          align: 'left',
          sortable: true,
        },
        {
          name: 'editor',
          label: '',
          field: 'editor',
          align: 'center',
          sortable: true,
        },
        {
          name: 'delete',
          label: '',
          field: 'delete',
          align: 'center',
          sortable: true,
        },
        {
          name: 'copy',
          label: '',
          field: 'copy',
          align: 'center',
          sortable: true,
        },
      ],
      pagination: {
        rowsPerPage: 20
      }
    }
  },
  methods: {
    ...mapActions('dynamic', ['getModuleData', 'saveModuleData', 'deleteModuleData']),

    setInitFields() {
      this.currentModule = false;
      this.showDeleteDialog = false;
      this.showCopyDialog = false;
      this.showCreateDialog = false;
      this.newNameValid = false;
      this.newModuleName = '';
    },

    onCancelCreateDialog() {
      this.setInitFields();
    },


    async onModuleCreate() {
      if (!this.newNameValid) {
        return false;
      }
      const newModule = {
        dropDownValues: {},
        dynamic: true,
        fields: {},
        menuIcon: 'far',
        name: this.newModuleName,
        relatesTo: [],
        sections: {},
        title: this.newModuleName
      };
      await this.saveModuleData({name: this.newModuleName, data: newModule});
      this.setInitFields();
    },

    async onModuleCopy() {
      if (!this.newNameValid) {
        return false;
      }

      const module = await this.getModuleData(this.currentModule.id);
      module.name = this.newModuleName;
      const copyData = {
        name: this.newModuleName,
        data: module
      };
      await this.saveModuleData(copyData);
      this.setInitFields();
    },
    onCancelCopyDialog() {
      this.setInitFields();
    },

    onClickDeleteBtn(props) {
      this.showDeleteDialog = true;
      this.currentModule = props.row;
    },
    async onModuleDelete() {
      await this.deleteModuleData(this.currentModule.id);
      this.setInitFields();
    },
    onCancelDeleteDialog() {
      this.setInitFields();
    },

    async onClickCopyBtn(module) {
      this.currentModule = module;
      this.showCopyDialog = true;
    },

    checkName() {
      let valid = this.newModuleName.length > 0;
      for (const modKey in this.dynamicModules) {
          if(this.dynamicModules[modKey]['id'] === this.newModuleName) {
            valid = false;
          }
      }
      this.newNameValid = valid;
    }
  },
  computed: {
    ...mapState(['dynamicModules', 'lineModules']),
    dynamicMenus() {
      return Object.values(this.dynamicModules).concat(Object.values(this.lineModules));
    }
  },
  watch: {
    newModuleName() {
      this.checkName();
    }
  },
}

